import * as React from 'react';
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Main from "./Main";
import { ToastProvider } from "react-toast-notifications";
import ReactGA from "react-ga4";

ReactGA.initialize("G-4YEM30H78K");

export default function App() {
  return <BrowserRouter>
    <ToastProvider>
      <Routes>
        <Route path="/" element={<Main/>}/>
      </Routes>
    </ToastProvider>
  </BrowserRouter>
}
