import * as React from 'react';
import { useEffect, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useSearchParams } from "react-router-dom";
import axios from 'axios';
import { Kiosk, Promotion, Response, ResponseItem } from "./model";
import { matchIsValidTel, MuiTelInput } from "mui-tel-input";
import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  Link,
  TextField
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import moment from 'moment';
import { useToasts } from "react-toast-notifications";
import ReactGA from "react-ga4";

const BASE = "https://api.6dbytes.com/v1"

export default function Main() {

  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false)
  const [promotion, setPromotion] = useState<Promotion>()
  const [phone, setPhone] = useState('')
  const [modalOpen, setModalOpen] = useState(false)
  const [otp, setOtp] = useState('')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState<string | undefined>(undefined)
  const [kiosks, setKiosks] = useState<Array<Kiosk>>()
  const [promotionError, setPromotionError] = useState<string | undefined>()

  const { addToast } = useToasts();

  const promoError = (msg: string) => {
    ReactGA.send({ hitType: "event", eventAction: msg });
    setPromotionError(msg)
  }

  useEffect(() => {
    const id = searchParams.get('id')
    if (!id) return

    setLoading(true)
    axios.get<ResponseItem<Promotion>>(`${BASE}/promotion/${id}/info`)
      .then(res => {
        const promo = res.data.value
        if (res.data.success) {

          if (promo.useLimitType != 'PER_USER') {
            promoError('Invalid Promotion: limit usage is not per user')
          } else if (moment(promotion?.endDate).isAfter(moment())) {
            promoError('Promotion has expired')
          } else if (promo.promotionsProgramOnNewUserAddedAction != 'ADD_TO_PROMO' && promo.promotionsProgramOnNewUserAddedAction != 'SEND_SMS') {
            promoError('Invalid Promotion: promotion action is not set')
          } else {
            setPromotion(promo)
            loadKiosks()
            ReactGA.send({ hitType: "event", eventAction: promo.name });
          }
        } else {
          promoError('Promotion not found');
        }
        setLoading(false)
      }, error => {
        console.log(error)
        setLoading(false)
        promoError('Internal error');
      })

  }, [searchParams]);

  const loadKiosks = () => {
    axios.get<ResponseItem<Array<Kiosk>>>(`${BASE}/kiosk/list`)
      .then(res => {
        if (res.data.success) {
          setKiosks(res.data.value)
        }
      }, error => {
        console.log(error)
      })
  }

  const submit = () => {
    if (!terms) {
      addToast("Please accept Terms and Conditions", { appearance: 'error' });
      return
    }

    ReactGA.send({ hitType: "event", eventAction: "Submit Phone" });

    setLoading(true)
    axios.get<Response>(`${BASE}/users/2fa/send/${phone.replace(/\s/g, '')}`)
      .then(res => {
        if (res.data.success) {
          setModalOpen(true)
        }
        setLoading(false)
      }, error => {
        setLoading(false)
      })
  }

  const subscribe = () => {
    if (!promotion) return

    setLoading(true)
    ReactGA.send({
      hitType: "event",
      eventAction: "Click Subscribe",
      allowMarketingSms: marketing,
      allowNotificationSms: terms,
      allowNotificationEmail: allowEmail,
      allowMarketingEmail: allowEmail,
    });

    const body = {
      "validationCode": otp,
      "phoneNumber": phone.replace(/\s/g, ''),
      "email": email,
      "allowMarketingSms": marketing,
      "allowNotificationSms": terms,
      "allowNotificationEmail": allowEmail,
      "allowMarketingEmail": allowEmail,
    }

    axios.post<Response>(`${BASE}/promotion/${promotion.id}/phone_number/`, body)
      .then(res => {
        if (res.data.success) {
          ReactGA.send({ hitType: "event", eventAction: "Subscribe Success" });

          closeModal();
          setSuccess(true)
        } else {
          setPromotionError(res.data.errorDescription)
        }
        setLoading(false)
      }, error => {
        ReactGA.send({ hitType: "event", eventAction: "Subscribe Failure" });
        setLoading(false)
      })

  };

  const closeModal = () => {
    setModalOpen(false);
    setOtp('')
    setPromotionError(undefined)
  };

  const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development'

  const [terms, setTerms] = useState(false)
  const [marketing, setMarketing] = useState(false)
  const [notifications, setNotifications] = useState(false)
  const [allowEmail, setAllowEmail] = useState(true)
  const [email, setEmail] = useState("")

  const getKioskDetails = (id: number) => {
    return kiosks?.find(it => it.id == id)
  }

  return <>
    <React.Fragment>

      <Box sx={{ minHeight: "calc(100vh - 30px)" }}>
        <Grid container style={{ backgroundColor: "#f2e6de" }}>
          <Grid item lg={"auto"}>
            <a href="https://blendid.com">
              <img src="img/logo.png" alt="Blendid" width="70" style={{ margin: "10px" }}/>
            </a>
          </Grid>
          <Grid item>
            <a href="https://blendid.com">
              <img src="img/logo-text.png" alt="Blendid" width="120" style={{ marginTop: "20px" }}/>
            </a>
            <br/>
            <Typography variant="body2" style={{ fontSize: "10px" }}>Real Food. Artificial Intelligence.</Typography>
          </Grid>
        </Grid>

        {!loading && <>
          {promotion ?
            <Container maxWidth="md">
              <Box sx={{ my: 4 }}>
                <Typography variant="h4" component="h1" sx={{ mb: 4, color: "#362566", fontWeight: 200, }}>
                  Welcome to Blendid Promotions Program!
                </Typography>

                <Grid container>
                  <Grid item md={6}>
                    {promotion.adCreativeImageUrl &&
                      <img style={{ height: "400px", borderRadius: "5px" }} src={promotion.adCreativeImageUrl}
                           alt={promotion.name}/>}
                  </Grid>

                  <Grid item md={6}>
                    {promotion.coupon?.name &&
                      <Typography variant="h4" style={{
                        padding: "10px",
                        textAlign: "center",
                        backgroundColor: "#f2e6de",
                        borderRadius: "50px",
                        paddingTop: "14px",
                        fontWeight: 400,
                        color: "#362566"
                      }}>{promotion.coupon?.name}</Typography>
                    }
                  </Grid>
                </Grid>


                {!success &&
                  <div style={{ marginTop: "10px" }}>
                    {promotion.promotionsProgramOnNewUserAddedAction == 'ADD_TO_PROMO' && <Typography variant="h5">
                      Enter your phone number and we will credit your account with a coupon
                      for {+promotion.parameters.DISCOUNT_VALUE * 100}% off your drink.
                    </Typography>}

                    {promotion.promotionsProgramOnNewUserAddedAction == 'SEND_SMS' && <Typography variant="h5">
                      Enter your phone number and we will send you the
                      code{promotion.coupon?.name ? `: ${promotion.coupon?.name}` : ``} via SMS and a link to our app.
                    </Typography>}
                  </div>
                }

                {success && <>
                  <Typography variant="h5">
                    <b>Thank you!</b> Your account has been credited with a coupon and will be available next time you
                    purchase a drink from Blendid. Offer valid at:
                  </Typography>
                  {promotion?.targetKiosks?.map(it => <Typography variant="h5" sx={{ m: 1, ml: 3 }}>
                    <b>{it.name}</b> - {getKioskDetails(it.id)?.addressLine1} {getKioskDetails(it.id)?.addressLine2}
                  </Typography>)}
                  <Typography variant="h5">
                    Offer expires <b>{moment(promotion?.endDate).format('l')}</b>.
                  </Typography>
                </>
                }

              </Box>

              {!success &&
                <Box sx={{ my: 4 }}>
                  <Grid container>
                    <Grid item lg={8}>
                      <MuiTelInput fullWidth onlyCountries={[isDev() ? 'UA' : 'US']}
                                   defaultCountry={isDev() ? 'UA' : 'US'}
                                   value={phone}
                                   onChange={(val) => setPhone(val)}/>
                    </Grid>

                    <Grid item lg={4}>
                      <Button disabled={!matchIsValidTel(phone)}
                              onClick={() => submit()}
                              fullWidth sx={{ height: '100%', px: 5, mx: 2 }}
                              variant="contained">Submit</Button>
                    </Grid>

                    <Grid item lg={12} sx={{ mb: 1, mt: 3 }}>
                      <FormControlLabel
                        control={
                          <Checkbox checked={terms} onChange={(event) => setTerms(event.target.checked)}
                                    name="terms"/>
                        }
                        label={<>Accept <Link href="https://blendid.com/privacy/">Terms and Conditions</Link></>}
                      />
                    </Grid>

                    <Grid item lg={12} sx={{ mb: 1 }}>
                      <FormControlLabel
                        componentsProps={{ typography: { variant: 'body2' } }}
                        control={
                          <Checkbox checked={notifications} onChange={(event) => setNotifications(event.target.checked)}
                                    name="notifications"/>
                        }
                        label="Authorize Blendid to send text with instructions to order and pick up. Message/data rates apply. You can opt out anytime by sending STOP."
                      />
                    </Grid>

                    <Grid item lg={12} sx={{ mb: 1 }}>
                      <FormControlLabel
                        componentsProps={{ typography: { variant: 'body2' } }}
                        control={
                          <Checkbox checked={marketing} onChange={(event) => setMarketing(event.target.checked)}
                                    name="marketing"/>
                        }
                        label="Authorize Blendid to send texts with with marketing and promotional offers such as coupons and surveys. Message/data rates apply. You can opt out anytime by sending STOP."
                      />
                    </Grid>
                  </Grid>
                </Box>
              }
            </Container>
            :
            <Container maxWidth="md">
              <Box sx={{ my: 4 }}>
                <Typography variant="h5" component="h1" sx={{ mb: 4 }}>
                  {promotionError}
                </Typography>
              </Box>
            </Container>
          }
        </>
        }

      </Box>
      <Grid container justifyContent="center"
            alignItems="center" style={{ backgroundColor: "#f2e6de", height: "30px" }}>
        <Grid item>
          <Typography variant="body2" style={{ fontSize: "10px" }}>Learn more at <a
            href="https://blendid.com">blendid.com</a></Typography>
        </Grid>
      </Grid>

    </React.Fragment>

    <Dialog
      open={modalOpen}
      onClose={closeModal}>
      <DialogTitle>Complete subscription</DialogTitle>
      <DialogContent>
        <MuiOtpInput length={6}
                     TextFieldsProps={{ sx: { '.MuiInputBase-input': { fontSize: '2rem' } } }}
                     autoFocus
                     validateChar={(character: string, index: number) => /^\d+$/.test(character)}
                     value={otp} onChange={(val) => setOtp(val)}/>

        <Typography variant="body1" color="error" mt={2}>
          {promotionError}
        </Typography>


        <Grid item lg={12} sx={{ my: 2 }}>

          <TextField type="email" fullWidth label="Email" variant="outlined"
                     onChange={(event) => setEmail(event.target.value)}/>

        </Grid>

        <Grid item lg={12} sx={{ my: 2 }}>
          <FormControlLabel
            componentsProps={{ typography: { variant: 'body2' } }}
            control={
              <Checkbox checked={allowEmail} onChange={(event) => setAllowEmail(event.target.checked)}
                        name="marketing"/>
            }
            label="Authorize Blendid to send email with receipts. You can opt out anytime by unsubscribing"
          />
        </Grid>

      </DialogContent>
      <DialogActions>
        <Button onClick={closeModal}>Cancel</Button>
        <Button disabled={otp.length != 6} onClick={subscribe}>Subscribe</Button>
      </DialogActions>
    </Dialog>

    <Backdrop
      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}>
      <CircularProgress color="inherit"/>
    </Backdrop>
  </>;
}
